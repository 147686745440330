import Vue from "vue";
import Router from "vue-router";
import auth from "/src/core/services/store/auth.module.js";

Vue.use(Router);

const router = new Router({
  // Add 'hash' mode as you are not using 'history' mode
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      // beforeRouteEnter(to, from, next) {
      //     if (userSessionIsActive()) {
      //         next()
      //     } else {
      //         next('/login')
      //     }
      // },
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/dashboard/:id",
          name: "dashboardwithid",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/dashboard/match/:id",
          name: "dashboardmatch",
          component: () => import("@/view/pages/Match.vue"),
        },
        {
          path: "/dashboard/notification/:id",
          name: "notification",
          component: () =>
            import("@/view/layout/extras/offcanvas/QuickPanel.vue"),
        },
        {
          path: "/dashboard/settings/:id",
          name: "settings",
          component: () => import("@/view/pages/settingscomp/settingpage.vue"),
        },
        {
          path: "/dashboard/feedback/:id",
          name: "feedback",
          component: () => import("@/view/pages/feedback.vue"),
        },
        {
          path: "/dashboard/review/:id",
          name: "dashboardreview",
          component: () => import("@/view/pages/Review.vue"),
        },
        {
          path: "/dashboard/reviewdata/:id",
          name: "dashboardreviewdata",
          component: () => import("@/view/pages/ReviewData.vue"),
        },
        {
          path: "/dashboard/paysucess/:id",
          name: "dashboardpaysucess",
          component: () => import("@/view/pages/PaySuccess.vue"),
        },
        {
          path: "/dashboard/pay/:id",
          name: "dashboardpay",
          component: () => import("@/view/pages/Pay.vue"),
        },
        // {
        //     path: "/dashboard/:filter",
        //     name: "dashboard",
        //     component: () =>
        //         import ("@/view/pages/Dashboard.vue")
        // },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },
        {
          path: "/custom-wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue"),
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue"),
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue"),
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue"),
            },
          ],
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue"),
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue"),
            },
          ],
        },

        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "edit",
              name: "edit",
              component: () => import("@/view/pages/profile/Edit.vue"),
            },
          ],
        },
        
      ],
    },
    {
      path: "/forgotpassword/:password",
      name: "forgotpassword",
      component: () =>
        import("@/view/pages/profile/forgotpassword/forgotpasswordverify.vue"),
    },
    {
      path: "/changeemail/:password",
      name: "changeemail",
      component: () =>
        import("@/view/pages/profile/forgotpassword/changeemailverify.vue"),
    },
    {
      path: "/approveprocess/:password",
      name: "approveprocess",
      component: () => import("@/view/pages/profile/approveprocessverify.vue"),
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          path: "/testing/:id",
          name: "testing",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
        },
      ],
    },
    {
      path: "*",
      // redirect: "/404",
      redirect: "/dashboard",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = auth.state.isAuthenticated;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      }).catch(err => console.error('Navigation error:', err));
    } else {
      next();
    }
  } 
  else if (to.matched.some((record) => record.meta.guest)) {
    if (isAuthenticated) {
      next({ path: "/dashboard" }).catch(err => console.error('Navigation error:', err));
    } else {
      next();
    }
  } else {
    next();
  }
});



export default router;
