// action types
export const SET_RIGHT_ASIDE = 'setRightAside'

// mutation types

export default {
  state: {
    aside: {
      display: false,
      record: [],
    },
  },
  getters: {
    rightAsideConfig(state) {
      return state.aside
    },
  },
  actions: {
    /**
     * Set and replace the whole config
     * @param state
     * @param payload
     */
    [SET_RIGHT_ASIDE](state, payload) {
      state.commit(SET_RIGHT_ASIDE, payload)
    },
  },
  mutations: {
    [SET_RIGHT_ASIDE](state, payload) {
      state.aside.display = payload.diplay
      state.aside.record = payload.record
    },
  },
}
