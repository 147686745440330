<!-- <template>
    <router-view></router-view>
</template> -->

<template>
  <Mobile v-if="isMobile()"></Mobile>
  <router-view v-else></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "assets/vue-shepard.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import Mobile from "@/mobile.vue";
import Vue from "vue";

export default {
  name: "MetronicVue",
  components: {
    Mobile,
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    Vue.prototype.$dashboard_tour = true;
    Vue.prototype.$reviewdata_heatmap = true;

    // this.isMobile();
  },
  methods: {
    isMobile() {
      // alert(window.navigator.userAgent);
      // ipad
      return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
  },
};
</script>
