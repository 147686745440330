import ApiService from '@/core/services/api.service'
import Swal from 'sweetalert2'

// action types
export const UPDATE_TASKS = 'updateTask'
export const UPDATE_TAG = 'updateTag'
export const GET_CHARGES = 'getCharges'
export const UPDATE_TO_PROCESS = 'updateToProcess'

// mutation types
export const SET_TASK_ERROR = 'setTaskError'
export const SET_TASK_INFO = 'setTaskInfo'
export const SET_TASK_CHARGE = 'setTaskCharge'

const state = {
  errors: null,
  tasks: {},
  charges: {},
  currentTasks: [] // Make sure this is initialized as an array
}

const getters = {
  currentTasks(state) {
    return Array.isArray(state.tasks) ? state.tasks : [];
  },
  currentCharge(state) {
    return state.charges
  },
}

const actions = {
  [UPDATE_TASKS](context, filter) {
    // Swal.fire({
    //     title: "Please Wait",
    //     allowOutsideClick: false,
    //     onOpen: () => { Swal.showLoading(); }
    // })

    ApiService.setHeader()
    ApiService.post('apps/todo/tasks/list', { filter: filter.filter })
      .then(({ data }) => {
        context.commit(SET_TASK_INFO, data)
      })
      .catch(({ response }) => {
        // Swal.close();
        context.commit(SET_TASK_ERROR, response.data.errors)
      })
  },
  [UPDATE_TAG](context, data) {
    Swal.fire({
      title: 'Please Wait',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading()
      },
    })

    ApiService.setHeader()
    ApiService.get('apps/todo/task/tag/' + data.id + '/' + data.tag + '/' + data.flag)
      .then(() => {
        Swal.close()
      })
      .catch(({ response }) => {
        Swal.close()
        context.commit(SET_TASK_ERROR, response.data.errors)
      })
  },
  [GET_CHARGES](context, data) {
    Swal.fire({
      title: 'Please Wait',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading()
      },
    })

    ApiService.setHeader()
    ApiService.get('apps/todo/task/stripe/' + data.id)
      .then(({ data }) => {
        Swal.close()
        context.commit(SET_TASK_CHARGE, data)
      })
      .catch(({ response }) => {
        Swal.close()
        context.commit(SET_TASK_ERROR, response.data.errors)
      })
  },
  [UPDATE_TO_PROCESS](context, data) {
    Swal.fire({
      title: 'Please Wait',
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading()
      },
    })

    ApiService.setHeader()
    ApiService.post('apps/todo/task/process/' + data.id, {
      options: data.options,
    })
      .then(() => {
        Swal.close()
      })
      .catch(({ response }) => {
        Swal.close()
        context.commit(SET_TASK_ERROR, response.data.errors)
      })
  },
}

const mutations = {
  [SET_TASK_ERROR](state, error) {
    state.errors = error
  },
  [SET_TASK_INFO](state, tasks) {
    state.tasks = tasks
    state.errors = {}
  },
  [SET_TASK_CHARGE](state, charges) {
    state.charges = charges
    state.errors = {}
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
