import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth.module'
import htmlClass from './htmlclass.module'
import config from './config.module'
import rightAside from './right_aside.module'
import breadcrumbs from './breadcrumbs.module'
import profile from './profile.module'
import tasks from './tasks.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    tasks,
    rightAside,
  },
})
